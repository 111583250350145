@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'DeadStock';
  src: url('../src/assets/fonts/DeadStock.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akira';
  src: url('../src/assets/fonts/Akira.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../src/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.pattern-bg {
  background-image: url('../src/assets/patterns/topography.svg');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.pattern2-bg {
  background-image: url('../src/assets/patterns/diagonal-stripes.svg');
  background-size: 200px 200px;
  background-repeat: repeat;
}

.cardPattern-bg {
  background-image: url('../src/assets/patterns/glamorous.svg');
  background-size: 130px 130px;
  background-repeat: repeat;
  
}

.clipsPattern-bg {
  background-image: url('../src/assets/patterns/diagonal-lines.svg');
  background-size: 20px 20px;
  background-repeat: repeat;
  
}

.svg-title-image {
  display: block; /* Ensures the image is treated as a block element */
  margin-left: auto;
  margin-right: auto;
  width: auto; /* Adjust based on your design requirements */
  max-width: 200px; /* Maximum width */
  margin-bottom: 1rem; /* Spacing below the image */
}

.svg-banner-image {
  width: auto; /* Adjust based on your design requirements */
  max-width: 100%; /* Maximum width */
  margin-bottom: 1rem; /* Spacing below the image */
}
